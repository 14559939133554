import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../styles/colors';

import pharmacogxLogo from '../images/pharmacogx.png';
import toxicogxLogo from '../images/toxicogx.png';
import xevaLogo from '../images/xeva.png';
import coregxLogo from '../images/coregx.png';
import radiogxLogo from '../images/radiogx.png';

import pharmacodbLogo from '../images/pharmacodb-logo.png';
import toxicodbLogo from '../images/toxicodb-logo.png';
import xevadbLogo from '../images/xevadb-logo.png';
import orcestraLogo from '../images/orcestra.png';
import synergxLogo from '../images/synergx-logo.png';

const StyledItemGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    border-top: 1px solid #b0b0b0;
    margin-top:calc(6vh + 1em);

    &>* {
        text-align:center;
        width: 33%;
        margin-top: ${(props) => (props.type === 'software' ? '12.5vh' : '8vh')};
    }

    @media only screen and (max-width: 1023px) {
      &>* {
        text-align:center;
        width: 47%;
        margin-top: ${(props) => (props.type === 'software' ? '8vh' : '4vh')};
    }

    @media only screen and (max-width: 800px) {
      margin-top:8vh;
    }
  }
`;

const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h1 {
      font-size: calc(1vw + 0.7em);
      font-family: 'Sora', sans-serif;
      font-weight:400;
      letter-spacing:1px;
      padding: 6px;
    }
    
    img {
      width: calc(6vw + 5em);
    }
    .PharmacoGx, .ToxicoDB {
      width: calc(8vw + 7em);
    }

    .PharmacoDB {
      width: calc(8.5vw + 8em);
    }
    .ToxicoGx {
      margin-top: -2vh;
    }
`;

const ItemGrid = (props) => {
  const { type } = props;
  const [state, setState] = useState({
    items: [],
    itemLogos: [],
    itemUrls: [],
  });
  const packages = ['PharmacoGx', 'RadioGx', 'ToxicoGx', 'CoreGx', 'Xeva'];
  const packageLogos = [pharmacogxLogo, radiogxLogo, toxicogxLogo, coregxLogo, xevaLogo];
  const packageUrls = [
    'https://www.bioconductor.org/packages/release/bioc/html/PharmacoGx.html',
    'https://rdrr.io/cran/RadioGx/',
    'http://bioconductor.org/packages/devel/bioc/html/ToxicoGx.html',
    'https://bioconductor.org/packages/release/bioc/html/CoreGx.html',
    'http://bioconductor.org/packages/release/bioc/html/Xeva.html',
  ];
  const webapps = ['ORCESTRA', 'PharmacoDB', 'SYNERGxDB', 'ToxicoDB', 'XevaDB'];
  const webappLogos = [orcestraLogo, pharmacodbLogo, synergxLogo, toxicodbLogo, xevadbLogo];
  const webappUrls = [
    'https://www.orcestra.ca/',
    'https://pharmacodb.ca/',
    'https://www.synergxdb.ca/',
    'https://www.toxicodb.ca/',
    'https://www.xevadb.ca/',
  ];

  useEffect(() => {
    if (type === 'software') {
      setState({ items: packages, itemLogos: packageLogos, itemUrls: packageUrls });
    } else {
      setState({ items: webapps, itemLogos: webappLogos, itemUrls: webappUrls });
    }
  }, [type]);

  return (
    <StyledItemGrid type={type}>
      {state.items.map((x, i) => (
        <StyledItem key={x}>
          {state.itemLogos[i] ? (
            <a href={state.itemUrls[i]}><img className={x} src={state.itemLogos[i]} alt="x" /></a>
          ) : (
            <h1>{x}</h1>
          )}
        </StyledItem>
      ))}
    </StyledItemGrid>
  );
};

export default ItemGrid;
