/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import colors from '../styles/colors';
import ItemGrid from '../components/ItemGrid';
import Layout from '../components/Layout';

import logoImg from '../images/logo.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledLanding = styled.div`
    text-align:center;
    
    h2 {
        font-family: 'Karla', sans-serif;
        font-weight:300;
        font-size: calc(1.1vw + 0.8em);
        span {
            font-weight: 400;
        }
    }
    img {
        width: calc(45vw + 12em);
    }
    
    .highlight {
      color: ${colors.highlight};
    }
    
`;

const StyledIndex = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    width:100vw;
    height: 100vh;

    color: ${colors.main_text};

    .item-grid{
        width: 80%;
    }

    .slider-container {
      // display:flex;
      // justify-content: center;
      // align-items: center;
    }

    // slick
    
    .slick-next::before {
        content: '>' !important;
    }
    
    .slick-prev::before {
        content: '<' !important;
    }
    
    .slick-prev {
        left: -35px !important;
        @media (max-width : 500px) {
            left: -25px !important
        }
    }
    .slick-next {
        right: -35px !important;
        @media (max-width : 500px) {
            right: -25px !important
        }
    }
    
    .slick-next::before, .slick-prev::before {
        font-size:calc(2rem + 2vw) !important;
        color:${colors.main_text} !important;
        font-family: 'Dosis', sans-serif !important;
    }
    .slick-next, .slick-prev {
        height:50px;
    }
    
    .slick-slide {
        text-align:center;
        height: 100%;
        & > div {
            height: 100%;
        }
    }
    
    .slick-slide:focus {
        outline:none;
    }
    .slick-dots {
        top:calc(100% + 10px);
        & li button::before {
            color: ${colors.main_text} !important;
        }
    }

`;

const Index = () => {
  // determine which slide
  const [slide, setSlide] = useState('software packages');

  const slideHandler = (current) => {
    if (current === 0) {
      setSlide('software packages');
    } else {
      setSlide('web applications');
    }
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return <p className="slick-next" onClick={onClick} />;
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return <p className="slick-prev" onClick={onClick} />;
  };
  // animation states for the landing divs
  const landingVariants = {
    start: {
      scale: 1,
      opacity: [0, 1],
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
    after: {
      scale: 0.7,
      transition: {
        delay: 1,
        default: { duration: 0.3 },
      },
    },

  };
  // animation states for the grid
  const gridVariants = {
    show: {
      x: 0,
      y: [-80, -50],
      opacity: [0, 1],
      height: 'auto',
      transition: {
        delay: 0.2,
        default: { duration: 0.6 },
      },
    },
  };

  const landingControls = useAnimation();
  const gridControls = useAnimation();

  // for slides
  const settings = {
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    swipe: false,
    speed: 500,
    afterChange: (current) => slideHandler(current),
  };

  useEffect(() => {
    (async () => {
      await landingControls.start('start');
      await landingControls.start('after');
      return gridControls.start('show');
    })();
  }, []);

  return (
    <Layout>
      <StyledIndex className="index">
        <motion.div
          variants={landingVariants}
          animate={landingControls}
          initial={{ opacity: 0 }}
        >
          <StyledLanding className="landing">
            <img alt="CancerGxSuite" src={logoImg} />
            <h2>
              A collection of cancer-related
              {' '}
              <span className="highlight">{slide}</span>
              {' '}
              from the
              {' '}
              <span>BHKLab</span>
              .
            </h2>
          </StyledLanding>
        </motion.div>

        <motion.div
          variants={gridVariants}
          animate={gridControls}
          initial={{ opacity: 0, height: '0' }}
          className="item-grid"
        >
          <Slider {...settings} className="slider-container">
            <ItemGrid type="software" />
            <ItemGrid type="webapps" />
          </Slider>
        </motion.div>

      </StyledIndex>
    </Layout>
  );
};

export default Index;
